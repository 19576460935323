/* eslint-disable react-hooks/exhaustive-deps */
/*  eslint-disable no-unused-vars */
import axios from "axios";
import React, { lazy, memo, useEffect, useState } from "react";
import { createContext, useContext } from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import jwt_decode from "jwt-decode";
import { BASE_URL, tmpToken } from "../../utils/utils";
import { AiOutlineControl, AiOutlineReconciliation } from "react-icons/ai";
import { BsBuildings } from "react-icons/bs";
import { IoCashOutline } from "react-icons/io5";
import { GoFileSymlinkFile } from "react-icons/go";

import { HiOutlineViewGridAdd } from "react-icons/hi";

import {
  TbReportAnalytics,
  TbReportMoney,
  TbUsersGroup,
  TbDatabaseDollar,
} from "react-icons/tb";
import {
  MdOutlineCreditCardOff,
  MdCreditScore,
  MdOutlinePayments,
} from "react-icons/md";
import { LuLayoutDashboard, LuClipboardList } from "react-icons/lu";
import { LiaFileInvoiceDollarSolid, LiaUsersSolid } from "react-icons/lia";
import { BsFileEarmarkRuled, BsCashCoin } from "react-icons/bs";
import { getAuth } from "@mirats/mirats-auth";
import Loader from "../../components/Loader";
const MainWrapperContainer = lazy(() =>
  import("../../components/MainWrapperContainer")
);
export const AllContext = createContext();

export const useAllContext = () => {
  return useContext(AllContext);
};

const AllContextProvider = ({ children }) => {
  let loadingText = [
    "Gathering the latest data for a smooth experience... Hang tight!",
    "Whipping up the freshest data for you. Just a moment!",
    "Fetching the good stuff. Your patience is appreciated!",
    "Compiling the insights you need. Almost there!",
    "Bringing everything together for your seamless experience. Stay tuned!",
    "Hunting down the best data for you. It'll be worth the wait!",
    "Sifting through the data to bring you exactly what you need. Hold on!",
    "Fine-tuning the details for your optimal experience. Thanks for waiting!",
    "Assembling the pieces for a tailored update. Your patience is golden!",
    "Curating your content with care. A smooth ride is just ahead!",
  ];
  const [mainLoader, setMainLoader] = useState({
    open: true,
    msg:
      loadingText[Math.floor(Math.random() * 11)] ||
      "Whipping up the freshest data for you. Just a moment!",
    color: "text-primary",
  });
  const [globalLoader, setGlobalLoader] = useState({
    projectOrdersData: true,
    companiesData: true,
    clientsData: true,
    clientReportData: true,
    invoiceData: true,
    supplierData: true,
    paymentData: true,
    paymentReceivedData: true,
    creditNoteData: true,
    debitNoteData: true,
    expenseData: true,
    remittanceData: true,
    payrollData: true,
    reconciliation: true,
  });
  const [companyData, setCompanyData] = useState({
    companyId: localStorage.getItem("companyId") || process.env.REACT_APP_CID,
    companyName:
      localStorage.getItem("companyName") || process.env.REACT_APP_CNAME,
  });
  const navData = [
    {
      name: "Dashboard",
      icon: <LuLayoutDashboard size={22} />,
      link: "/",
    },
    {
      name: "Invoices",
      icon: <LiaFileInvoiceDollarSolid size={22} />,
      link: "/invoice",
    },
    {
      name: "Suppliers",
      icon: <LiaUsersSolid size={22} />,
      link:
        companyData?.companyId === process.env.REACT_APP_CID
          ? "/suppliers"
          : "/suppliers-v2",
    },
    {
      name: "Vendor Invoices",
      icon: <TbReportMoney size={22} />,
      link:
        companyData?.companyId === process.env.REACT_APP_CID
          ? "/vendor-invoices"
          : "/vendor-invoices-v2",
    },
    {
      name: "Client Report",
      icon: <TbReportAnalytics size={22} />,
      link: "/client-report",
    },
    {
      name: "Clients",
      icon: <TbUsersGroup size={22} />,
      link: "/clients",
    },
    {
      name: "Project Orders",
      icon: <LuClipboardList size={22} />,
      link:
        companyData?.companyId === process.env.REACT_APP_CID
          ? "/project-orders"
          : "/project-orders-v2",
    },
    {
      name: "PO by Vendors",
      icon: <BsFileEarmarkRuled size={22} />,
      link:
        companyData?.companyId === process.env.REACT_APP_CID
          ? "/po-by-vendor"
          : "/po-by-vendor-v2",
    },
    {
      name: "Companies",
      icon: <BsBuildings size={22} />,
      link: "/companies",
    },
    {
      name: "Remittance Advice",
      icon: <GoFileSymlinkFile size={22} />,
      link:
        companyData?.companyId === process.env.REACT_APP_CID
          ? "/remittance-advice"
          : "/remittance-advice-v2",
    },
    {
      name: "Reconciliation",
      icon: <AiOutlineReconciliation size={22} />,
      link: "/reconciliation",
    },
    {
      name: "Payments",
      icon: <BsCashCoin size={22} />,
      link:
        companyData?.companyId === process.env.REACT_APP_CID
          ? "/payments"
          : "/payments-v2",
    },
    {
      name: "Payment Received",
      icon: <IoCashOutline size={22} />,
      link: "/payment-received",
    },
    {
      name: "Credit Note",
      icon: <MdCreditScore size={22} />,
      link: "/credit-note",
    },
    {
      name: "Debit Note",
      icon: <MdOutlineCreditCardOff size={22} />,
      link: "/debit-note",
    },
    {
      name: "Expenses",
      icon: <TbDatabaseDollar size={22} />,
      link: "/expenses",
    },

    {
      name: "Payroll",
      icon: <MdOutlinePayments size={22} />,
      link: "/payroll",
    },
    {
      name: "Manage Users",
      icon: <AiOutlineControl size={22} />,
      link: "/manage-users",
    },
    {
      name: "Others",
      icon: <HiOutlineViewGridAdd size={22} />,
      link: "/others",
    },
  ];

  //GET LOGGED IN USER INFO:
  const [userData, setUserData] = useState({});
  const [greetMsg, setGreetMsg] = useState("");
  const getUserData = async () => {
    try {
      const user = await getAuth();
      setUserData({
        basicInfo: {
          //  ...data?.data?.[0],
          ...user?.currentUser,
        },
        // workDetails: {
        //   ...data?.data?.workDetails?.[0],
        // },
      });
      setMainLoader((prev) => ({
        open: false,
        msg: "",
        color: "text-primary",
      }));
      // if (user && user?.currentUser?._id) {
      //   await axios
      //     .get(
      //       `https://apiservice.peoples.atomostech.com/atomos/v1/peoples/employee/get-global-employees?id=${user?.currentUser?._id}`,
      //       {
      //         headers: {
      //           "Content-Type": "application/json",
      //           "api-key": "wkrX0NgBe5IStwGLVHOXMTbx8aNUilrzirwVi26MByw=",
      //         },
      //       }
      //     )
      //     .then(async (data) => {
      //       console.log(data);
      //       setUserData({
      //         basicInfo: { ...data?.data?.[0], ...user?.currentUser },
      //         workDetails: {
      //           ...data?.data?.workDetails?.[0],
      //         },
      //       });
      //       setMainLoader((prev) => ({
      //         open: false,
      //         msg: "",
      //         color: "text-primary",
      //       }));
      //       const userAccess = await axios.get(
      //         `${BASE_URL}/companyAccess?userId=${user?.currentUser?._id}`,
      //         {
      //           headers: {
      //             // "x-access-token": getCookie("token"),
      //             "x-access-token": tmpToken,
      //           },
      //         }
      //       );
      //       console.log(userAccess.data);
      //       setUserData((prev) => ({
      //         ...prev,
      //         pageAccess: userAccess?.data || {},
      //       }));
      //     })
      //     .catch((er) => {
      //       console.log(er);
      //       setMainLoader((prev) => ({
      //         open: true,
      //         msg: "Unable to get peoples user data ! try refreshing the page",
      //         color: "text-red",
      //       }));
      //       setOpen({
      //         open: true,
      //         msg: "Unable to peoples user data !",
      //         severity: "danger",
      //       });
      //     });
      // }
    } catch (error) {
      console.log(error);
    }
  };

  // GET ALL RECONCILIATIONS:
  const [reconciliations, setReconciliations] = useState([]);
  const getAllReconciliations = async (month, year) => {
    setGlobalLoader((prev) => ({ ...prev, reconciliation: true }));
    try {
      let data = await axios.get(
        `${BASE_URL}/reconciliation?company=${companyData?.companyId}&month=${month}&year=${year}`,
        {
          headers: {
            // "x-access-token":getCookie("token");
            "x-access-token": tmpToken,
          },
        }
      );
      setReconciliations(data.data);
      setGlobalLoader((prev) => ({ ...prev, reconciliation: false }));
    } catch (error) {
      console.error(error);
    }
  };

  //CURRENCIES:
  const currencies = [
    {
      name: "United States Dollar",
      code: "USD",
      symbol: "$",
    },
    {
      name: "Euro",
      code: "EUR",
      symbol: "€",
    },
    {
      name: "Japanese Yen",
      code: "JPY",
      symbol: "¥",
    },
    {
      name: "British Pound Sterling",
      code: "GBP",
      symbol: "£",
    },
    {
      name: "Australian Dollar",
      code: "AUD",
      symbol: "A$",
    },
    {
      name: "Canadian Dollar",
      code: "CAD",
      symbol: "C$",
    },
    {
      name: "Swiss Franc",
      code: "CHF",
      symbol: "Fr.",
    },
    {
      name: "Chinese Yuan",
      code: "CNY",
      symbol: "¥",
    },
    {
      name: "Indian Rupee",
      code: "INR",
      symbol: "₹",
    },
    {
      name: "Brazilian Real",
      code: "BRL",
      symbol: "R$",
    },
    {
      name: "South African Rand",
      code: "ZAR",
      symbol: "R",
    },
    {
      name: "Mexican Peso",
      code: "MXN",
      symbol: "$",
    },
    {
      name: "Singapore Dollar",
      code: "SGD",
      symbol: "S$",
    },
    {
      name: "Hong Kong Dollar",
      code: "HKD",
      symbol: "HK$",
    },
  ];

  //GET ALL COMPANIES:
  const [allCompanies, setAllCompanies] = useState([]);
  const [companiesData, setCompaniesData] = useState([]);
  const [company, setCompany] = useState({});
  async function getAllCompanies() {
    setGlobalLoader((prev) => ({ ...prev, companiesData: true }));
    try {
      const companiesData = await axios.get(`${BASE_URL}/company`, {
        headers: {
          // "x-access-token": getCookie("token"),
          "x-access-token": tmpToken,
        },
      });
      let companies = companiesData?.data?.map((companies) => {
        return {
          companyId: companies?._id,
          companyName: companies?.company_name,
        };
      });
      setGlobalLoader((prev) => ({ ...prev, companiesData: false }));
      setCompaniesData(companies);
      setAllCompanies(companiesData?.data);
    } catch (error) {
      console.error(error);
    }
  }

  //GET LAST 10 YEARS:
  const [monthYear, setMonthYear] = useState({});
  async function getYears() {
    let tempArr = [];
    for (let i = 0; i <= 10; i++) {
      tempArr.push(new Date()?.getFullYear() - i);
    }
    let months = [
      { month: "January", value: 1 },
      { month: "February", value: 2 },
      { month: "March", value: 3 },
      { month: "April", value: 4 },
      { month: "May", value: 5 },
      { month: "June", value: 6 },
      { month: "July", value: 7 },
      { month: "August", value: 8 },
      { month: "September", value: 9 },
      { month: "October", value: 10 },
      { month: "November", value: 11 },
      { month: "December", value: 12 },
    ];

    setMonthYear((prev) => ({ ...prev, years: tempArr, months: months }));
  }

  //GET STATE, COUNTRY AND STATE:
  const headers = new Headers();
  headers.append(
    "X-CSCAPI-KEY",
    "aGJKbVB4TXZwVVgzeDEzNXJId3hCeEx6MnM0UmhlUVJkTmR2NndEMw=="
  );
  const requestOptions = {
    method: "GET",
    headers: headers,
    redirect: "follow",
  };
  const [countryStateCity, setCountryStateCity] = useState({
    countries: [],
    state: [],
    cities: [],
  });

  //get countries:
  const getCountries = () => {
    fetch("https://api.countrystatecity.in/v1/countries", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setCountryStateCity((prev) => ({ ...prev, countries: result }));
      })
      .catch((error) => console.log("error", error));
  };

  //get states:
  const getStates = (country) => {
    fetch(
      `https://api.countrystatecity.in/v1/countries/${country}/states`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setCountryStateCity((prev) => ({ ...prev, state: result }));
      })
      .catch((error) => console.log("error", error));
  };

  //get states:
  const getCities = (country, state) => {
    console.log(country, state);
    fetch(
      `https://api.countrystatecity.in/v1/countries/${country}/states/${state}/cities`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) =>
        setCountryStateCity((prev) => ({ ...prev, cities: result }))
      )
      .catch((error) => console.log("error", error));
  };

  //SNACKBAR:
  const [open, setOpen] = useState({
    open: false,
    severity: "",
    msg: "",
  });
  const snackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen({ ...open, open: false });
  };
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  //GETTING CURRENT TIME AND PRINTING GREET MESSAGE:
  async function getGreetMsg() {
    const date = new Date();
    const nowtime = new Date(date).getHours();
    if (nowtime >= 5 && nowtime < 12) {
      setGreetMsg("Morning");
    } else if (nowtime >= 12 && nowtime <= 16) {
      setGreetMsg("Afternoon");
    } else if (nowtime > 16 && nowtime < 23) {
      setGreetMsg("Good Evening");
    } else {
      setGreetMsg("Night");
    }
  }

  //GET FINANCE MANAGERS:
  const [financeManagers, setFinanceManagers] = useState([]);
  async function getAllFinanceManagers() {
    try {
      const data = await axios.get(
        `https://peoples.miratsoneservices.com/api/v1/peoples/get_accountManagersData`
      );
      setFinanceManagers(data?.data);
    } catch (error) {
      console.error(error);
    }
  }

  //GET ALL BANK DETAILS:
  const [bankDetails, setBankDetails] = useState([]);
  const getAllBankDetails = async () => {
    try {
      const data = await axios.get(`${BASE_URL}/bank-details`, {
        headers: {
          // "x-access-token": getCookie("token"),
          "x-access-token": tmpToken,
        },
      });
      setBankDetails(data?.data);
    } catch (error) {
      console.error(error);
    }
  };

  //GET ALL CURRENCY DETAILS:
  const [currencyDetails, setCurrencyDetails] = useState([]);
  const getAllCurrenyDetails = async () => {
    try {
      const data = await axios.get(`${BASE_URL}/currencies`, {
        headers: {
          // "x-access-token": getCookie("token"),
          "x-access-token": tmpToken,
        },
      });
      setCurrencyDetails(data?.data);
    } catch (error) {
      console.error(error);
    }
  };

  //GET ALL PAYMENT METHOD DETAILS:
  const [paymentMethodDetails, setPaymentMethodDetails] = useState([]);
  const getAllPaymentMethods = async () => {
    try {
      const data = await axios.get(`${BASE_URL}/payment-methods`, {
        headers: {
          // "x-access-token": getCookie("token"),
          "x-access-token": tmpToken,
        },
      });
      setPaymentMethodDetails(data?.data);
    } catch (error) {
      console.error(error);
    }
  };

  //GET ALL EXPENSES:
  const [expenses, setAllExpenses] = useState([]);
  const getAllExpenses = async (company, month, year) => {
    setGlobalLoader((prev) => ({ ...prev, expenseData: true }));
    try {
      const data = await axios.get(
        `${BASE_URL}/expenses?company=${company}&month=${month}&year=${year}`,
        {
          headers: {
            "x-access-token": tmpToken,
          },
        }
      );
      setGlobalLoader((prev) => ({ ...prev, expenseData: false }));
      setAllExpenses(data?.data?.expenseData);
    } catch (error) {
      setGlobalLoader((prev) => ({ ...prev, expenseData: false }));
      console.error(error);
    }
  };

  // remitance
  const [allRemittance, setAllRemittance] = useState([]);
  const [loading, setLoading] = useState(false);
  async function getAllRemittanceAdvise(month, year) {
    setGlobalLoader((prev) => ({ ...prev, remittanceData: true }));
    try {
      const allRemittance = await axios.get(
        `${BASE_URL}/${
          companyData?.companyId === process.env.REACT_APP_CID
            ? `remittance-advise?company=${companyData?.companyId}&month=${month}&year=${year}`
            : `remittance-advise-v2?id=${companyData?.companyId}`
        }`,
        {
          headers: {
            // "x-access-token": getCookie("token"),
            "x-access-token": tmpToken,
          },
        }
      );
      setAllRemittance(allRemittance?.data);
      setGlobalLoader((prev) => ({ ...prev, remittanceData: false }));
    } catch (error) {
      console.error(error);
      setGlobalLoader((prev) => ({ ...prev, remittanceData: false }));
    }
  }
  const [isEmailSent, setIsEmailSent] = useState(() => {
    let data = JSON.parse(
      localStorage.getItem(`${companyData?.companyId}-isEmailSent`)
    );
    if (data) {
      return data;
    } else {
      return [];
    }
  });

  // CHECK IS EMAIL SENT:
  const checkIsEmailSent = async (e) => {
    try {
      if (!isEmailSent?.length) {
        setLoading(true);
      }
      const data = await axios.get(
        `${BASE_URL}/${
          companyData?.companyId === process.env.REACT_APP_CID
            ? "remittance-advise/email"
            : "remittance-advise-v2/email"
        }`,
        {
          headers: {
            // "x-access-token": getCookie("token"),
            "x-access-token": tmpToken,
          },
        }
      );
      localStorage.setItem(
        `${companyData?.companyId}-isEmailSent`,
        JSON.stringify(data?.data)
      );
      setIsEmailSent(data?.data);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log("main global context got called");
    getYears();
    getCountries();
    getGreetMsg();
    if (Object.keys(userData || {})?.length) {
      getAllCurrenyDetails();
      getAllPaymentMethods();
      getAllBankDetails();
      getAllFinanceManagers();
      getAllCompanies();
      checkIsEmailSent();
    } else {
      getUserData();
    }
  }, [companyData?.companyId, userData]);

  const value = {
    userData,
    getUserData,
    greetMsg,
    getAllCompanies,
    allCompanies,
    setAllCompanies,
    companiesData,
    setCompaniesData,
    reconciliations,
    setReconciliations,
    getAllReconciliations,
    allRemittance,
    company,
    isEmailSent,
    checkIsEmailSent,
    setCompany,
    companyData,
    setCompanyData,
    currencies,
    monthYear,
    setMonthYear,
    countryStateCity,
    setCountryStateCity,
    getStates,
    getCities,
    open,
    setOpen,
    loading,
    setLoading,
    financeManagers,

    globalLoader,
    setGlobalLoader,

    bankDetails,
    getAllBankDetails,

    currencyDetails,
    getAllCurrenyDetails,

    paymentMethodDetails,
    getAllPaymentMethods,
    navData,
    expenses,
    getAllExpenses,
    setAllExpenses,

    getAllRemittanceAdvise,
  };

  return (
    <AllContext.Provider value={value}>
      <MainWrapperContainer>{children}</MainWrapperContainer>
      <Snackbar
        open={open?.open}
        autoHideDuration={3000}
        onClose={snackbarClose}
        bodyStyle={{ maxWidth: "100%" }}
      >
        <Alert
          onClose={snackbarClose}
          severity={open?.severity}
          sx={{ width: "100%" }}
        >
          {open?.msg}
        </Alert>
      </Snackbar>
      {mainLoader?.open ? (
        <div className="w-screen flex items-center justify-center h-screen bg-[#fff] fixed top-0 z-[99999]">
          <div className="flex items-center gap-[15px]">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              style={{ margin: "auto", background: "#fff", display: "block" }}
              width="66px"
              height="66px"
              viewBox="0 0 100 100"
              preserveAspectRatio="xMidYMid"
            >
              <circle
                cx="50"
                cy="50"
                r="32"
                strokeWidth="8"
                stroke="#4285F4"
                strokeDasharray="50.26548245743669 50.26548245743669"
                fill="none"
                strokeLinecap="round"
              >
                <animateTransform
                  attributeName="transform"
                  type="rotate"
                  dur="1s"
                  repeatCount="indefinite"
                  keyTimes="0;1"
                  values="0 50 50;360 50 50"
                ></animateTransform>
              </circle>
              <circle
                cx="50"
                cy="50"
                r="23"
                strokeWidth="8"
                stroke="#4285F4"
                strokeDasharray="36.12831551628262 36.12831551628262"
                strokeDashoffset="36.12831551628262"
                fill="none"
                strokeLinecap="round"
              >
                <animateTransform
                  attributeName="transform"
                  type="rotate"
                  dur="1s"
                  repeatCount="indefinite"
                  keyTimes="0;1"
                  values="0 50 50;-360 50 50"
                ></animateTransform>
              </circle>
            </svg>
            <h1
              className={`text-[#333] font-[500] text-[18px] ${mainLoader?.color}`}
            >
              {" "}
              {mainLoader?.msg}
            </h1>
          </div>
        </div>
      ) : (
        ""
      )}
    </AllContext.Provider>
  );
};

export default memo(AllContextProvider);
